import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the interface for CartItem
interface CartItem {
  id: number;
  name: string;
  price: number;
  quantity: number;
  image: any; // Adjust as per your actual image type
  slug: any; // Adjust as per your actual slug type
}

// Define the interface for CartState
interface CartState {
  items: CartItem[];
  totalItems: number;
  totalPrice: number;
}

// Define initial state for CartState
const initialState: CartState = {
  items: [],
  totalItems: 0,
  totalPrice: 0,
};

// Create cartSlice using createSlice from Redux Toolkit
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // Add item to cart or update quantity if already exists
    addToCart: (state, action: PayloadAction<CartItem>) => {
      const { id, quantity, price } = action.payload;
      const existingItemIndex = state.items.findIndex((item) => item.id === id);

      if (existingItemIndex !== -1) {
        // Item already exists in the cart, update its quantity and price
        state.items[existingItemIndex].quantity += quantity;
        state.items[existingItemIndex].price =
          state.items[existingItemIndex].quantity * price; // Assuming price updates with quantity
      } else {
        // Item is not in the cart, add it
        state.items.push(action.payload);
      }

      // Update totalItems and totalPrice
      state.totalItems = state.items.reduce(
        (total, item) => total + item.quantity,
        0
      );
      state.totalPrice = state.items.reduce(
        (total, item) => total + item.price,
        0
      );
    },

    // Remove item from cart by ID
    removeFromCart: (state, action: PayloadAction<number>) => {
      const itemIdToRemove = action.payload;
      state.items = state.items.filter((item) => item.id !== itemIdToRemove);

      // Update totalItems and totalPrice
      state.totalItems = state.items.reduce(
        (total, item) => total + item.quantity,
        0
      );
      state.totalPrice = state.items.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );
    },

    // Increment quantity of an item in the cart
    incrementQuantity: (state, action: PayloadAction<number>) => {
      const itemIdToIncrement = action.payload;
      const itemToIncrement = state.items.find(
        (item) => item.id === itemIdToIncrement
      );
      if (itemToIncrement) {
        itemToIncrement.quantity += 1;
      }

      // Update totalPrice
      state.totalPrice = state.items.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );
    },

    // Decrement quantity of an item in the cart
    decrementQuantity: (state, action: PayloadAction<number>) => {
      const itemIdToDecrement = action.payload;
      const itemToDecrement = state.items.find(
        (item) => item.id === itemIdToDecrement
      );
      if (itemToDecrement && itemToDecrement.quantity > 1) {
        itemToDecrement.quantity -= 1;
      }

      // Update totalPrice
      state.totalPrice = state.items.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );
    },

    // Add single item to cart
    addItem: (state, action: PayloadAction<CartItem>) => {
      const { id, price } = action.payload;
      const existingItem = state.items.find((item) => item.id === id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.items.push({ ...action.payload, quantity: 1 });
      }
      state.totalPrice += price;
    },

    // Clear all items from cart
    clearCart: (state) => {
      state.items = [];
      state.totalItems = 0;
      state.totalPrice = 0;
    },
  },
});

// Extract action creators and reducer from cartSlice
export const {
  addToCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
  addItem,
  clearCart,
} = cartSlice.actions;

// Export reducer function for cartSlice
export default cartSlice.reducer;
