// store.ts
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import ContainerData from "./Auth";
import cartReducer from "./Cart/cartSlice"; // Import cartReducer

export type RootState = ReturnType<typeof store.getState>;

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  ContainerData: ContainerData,
  cart: cartReducer, // Include cartReducer in combineReducers
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: {
    persistedReducer,
  },
});

export default store;
